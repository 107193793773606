<template>
  <div style="background-color:white">
    <el-container>
      <el-header>
        <div class="detail_title">시험후기</div>
        <div class="detail-header-wrapper">
          <div></div>
          <div
            v-if="board['board'].flag === 3"
            class="detail_title"
            style="text-align:center;margin-left: 15%;"
          >
            {{ board["board"].title }} 🔒
          </div>
          <div
            v-else
            class="detail_title"
            style="text-align:center;margin-left: 15%;"
          >
            {{ board["board"].title }}
          </div>
          <div class="detail_subtitle">
            <span> 작성자 : {{ board["board"].real_name }} </span>
            <span> 작성일 : {{ toLocal(board["board"].created_time) }} </span>
          </div>
        </div>
      </el-header>
      <hr />
      <el-main>
        <div class="detail_content">
          <div class="detail_optionbtn">
            <el-button @click="list">목록</el-button>

            <el-button
                v-if="isSuperAdmin ||
                  ((user.profile.user &&
                  user.profile.user.id) === (board['board']['created_by'] && board['board'].created_by.id))"
              @click="updateData">수정</el-button>

            <el-button
              @click="secretBoard(board['board'].flag)"
                v-if="isSuperAdmin ||
                  ((user.profile.user &&
                  user.profile.user.id) === (board['board']['created_by'] && board['board'].created_by.id))"
              >
              <span v-if="board['board'].flag == 3"> 공개 </span>
              <span v-else> 비공개 </span>
            </el-button>

            <el-button @click="deleteBoard" v-if="isSuperAdmin ||
            ((user.profile.user &&
            user.profile.user.id) === (board['board']['created_by'] && board['board'].created_by.id))">삭제</el-button>

          </div>
          <BoardHighlight :code="board['board'].content"></BoardHighlight>
        </div>
      </el-main>
      <el-footer>
        <div
          v-for="c in board['board'].comments"
          :key="c.id"
          class="detail_comment"
        >
          <div class="board_comment-wrapper">
            <div class="board_comment">
              <span class="detail_comment_font">{{ c.real_name }} </span>
              <span class="detail_comment_font">
                {{ commentToLocal(c.created_time) }}</span
              >
              <div style="margin-left:auto;">
                <el-button
                  class="detail_comment_button"
                  @click="chageflag(c.id, c.comment)"
                  :disabled=flag
                  v-if="
                    isSuperAdmin ||
                      (commentIndex !== c.id &&
                        user.profile.user &&
                        user.profile.user.id === c.created_by.id)
                  "
                  >수정</el-button
                >
                <el-button
                  class="detail_comment_button"
                  @click="secretComment(c.id, c.flag)"
                  v-if="
                    isSuperAdmin ||
                      (commentIndex !== c.id &&
                        user.profile.user &&
                        user.profile.user.id === c.created_by.id)
                  "
                >
                  <span v-if="c.flag == 3"> 공개 </span>
                  <span v-else> 비공개 </span>
                </el-button>
                <el-button
                  class="detail_comment_button"
                  @click="deleteComment(c.id)"
                  v-if="
                    isSuperAdmin ||
                      (user.profile.user &&
                        user.profile.user.id === c.created_by.id)
                  "
                  >삭제</el-button
                >
              </div>
            </div>
            <div v-if="flag && commentIndex === c.id" class="textarea">
              <el-input
                class="textarea"
                type="textarea"
                :rows="3"
                autosize
                placeholder="댓글을 입력해주세요"
                v-model="comment"
              ></el-input>

              <el-button
                class="detail_comment_button"
                @click="changeComment(c.id)"
                >변경</el-button
              >
              <el-button
                class="detail_comment_button"
                @click="cancle()"
                >취소</el-button
              >
            </div>
            <div
              v-if="
                c.flag === 3 &&
                  (isSuperAdmin || c.created_by.id === user.profile.user.id) && !flag
              "
              v-html="c.comment"
              class="comment-font"
            >
              🔒
            </div>
            <div class="comment-font" v-else-if="c.flag === 3 && !flag">비공개 댓글입니다. 🔒</div>
            <div class="comment-font" v-else-if="!flag">
                <ChangeCommentText :comment="c.comment"></ChangeCommentText>
            </div>
            <!-- <div class="comment-font" v-else-if="!flag" v-html="c.comment"></div> -->
          </div>
        </div>
        <Comment />
      </el-footer>
    </el-container>
  </div>
</template>

<script>
// import time from "@/utils/time";
import api from "@oj/api";
// import Comment from "./Comment";
// import { mapState, mapActions, mapGetters } from "vuex";
// import BoardHighlight from "@/pages/oj/components/BoardHighlight";
import mixins from "./detailMixins";
export default {
  mixins: [mixins],
  name: "TestReviewMiracomDetail",
  props: ["code"],

  beforeUpdate() {
    // if (this.isPermission() === false && this.isPublicPost() === false) {
    //   this.$router.push("/");
    //   this.$error("권한이 없습니다.");
    // }
  },
  methods: {
    deleteBoard() {
      if (this.isPermission()) {
        this.$Modal.confirm({
          content: "게시글을 삭제하시겠습니까?",
          onOk: async () => {
            await api.deleteBoard(this.$route.params["board_id"]);
            this.$router.push({
              path: "/test-review-board-miracom",
              query: { page: this.$route.query.page }
            });
          },
          onCancel: () => {}
        });
      } else {
        this.$error("권한이 없습니다.");
      }
    },
    updateData() {
      if (this.isPermission()) {
        this.$router.push({
          path: `/test-review-miracom-create/${this.$route.params["board_id"]}`,
          query: { page: this.$route.query.page }
        });
      } else {
        this.$error("권한이 없습니다.");
      }
    },
    list() {
      this.$router.push({
        path: "/test-review-board-miracom",
        query: { page: this.$route.query.page }
      });
    }
    // write() {
    //   this.$router.push({
    //     path: "/create-miracom",
    //     query: { page: this.$route.query.page }
    //   });
    // },
    // deleteComment(id) {
    //   this.$Modal.confirm({
    //     content: "댓글을 삭제하시겠습니까?",
    //     onOk: async () => {
    //       const hello = await api.deleteComment(id);
    //       const reuslt = await this.getBoard(this.$route.params["board_id"]);
    //     },
    //     onCancel: () => {}
    //   });
    // },
    // chageflag(id, comment) {
    //   this.flag = true;
    //   this.commentIndex = id;
    //   let str = comment.replace(/<br\s*\/?>/gm, "\n");
    //   this.comment = str;
    // },
    // async changeComment(comment_id) {
    //   if (!this.comment.trim().length) {
    //     this.$error("댓글을 입력해주세요");
    //     return;
    //   }
    //   let str = this.comment.replace(/ /g, "\u00a0");
    //   str = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
    //   this.$Modal.confirm({
    //     content: "댓글을 수정하시겠습니까?",
    //     onOk: async () => {
    //       await api.putComment(comment_id, str);
    //       const reuslt = await this.getBoard(this.$route.params["board_id"]);
    //       this.flag = false;
    //       this.comment = "";
    //       this.commentIndex = "";
    //     },
    //     onCancel: () => {}
    //   });
    // },
    // secretBoard(flag) {
    //   let type = "";
    //   if (flag === 3) type = "normal";
    //   else type = "secret";
    //   this.$Modal.confirm({
    //     content: `해당 게시글을 ${
    //       type === "normal" ? "공개" : "비공개"
    //     } 처리 하시겠습니까?`,
    //     onOk: async () => {
    //       await api.secretBoard(this.$route.params["board_id"], type);
    //       const reuslt = await this.getBoard(this.$route.params["board_id"]);
    //     },
    //     onCancel: () => {}
    //   });
    // },
    // secretComment(id, flag) {
    //   let type = "";
    //   if (flag === 3) type = "normal";
    //   else type = "secret";
    //   this.$Modal.confirm({
    //     content: `해당 댓글을 ${
    //       type === "normal" ? "공개" : "비공개"
    //     } 처리 하시겠습니까?`,
    //     onOk: async () => {
    //       await api.secretComment(id, type);
    //       const reuslt = await this.getBoard(this.$route.params["board_id"]);
    //     },
    //     onCancel: () => {}
    //   });
    // }
  }
};
</script>
<style scoped lang="less">

.create-container{
  padding:0 13% !important;
}

@media (max-width:618px) {
  .create-container{
    
    padding: 0 !important;
  }
}
.el-header {
  background: white;
  padding: 40px 40px;
  height: 100% !important;
}
.el-footer {
  height: 100%;
}
.el-main {
  background: white;
  min-height: 30vh;
}
.el-footer {
  height: 100% !important;
  padding: 0 !important;
}

.el-input input {
  border-top: 1px solid #eeeeee;
}
body .el-container {
  margin-bottom: 40px;
  padding: 30px 20px;
  width:90%;
  margin:0px auto;
}
.detail-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.detail_title {
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 10px;
  word-break: break-all;
}

.detail_subtitle {
  margin-right: 10px;
  text-align: left;
  display: flex;
}
.detail_subtitle > span {
  margin-left: 30px;
}



.detail_content {
  // min-height: 100px;
  // font-size: 16px;
  // width: 100%;
}
.detail_optionbtn {
  text-align: right;
}

.detail_comment {
  border: 1px solid #eeeeee;
  background-color: white;
  padding: 30px 24px;
  margin-bottom:10px;
}
.detail_comment_button {
  margin-left: auto;
  font-size: 12px;
}
.detail_comment_font {
  font-size: 13px;
  font-weight: 600;
  margin-right: 20px;
}

.board_comment-wrapper {
  display: flex;
  flex-direction: column;
}
.board_comment {
  display: flex;
}
.board_comment button {
  padding: 11px !important;
}
.comment .el-textarea__inner {
  min-height: 45px !important;
}

.comment-font {
  // font-size: 14px;
  // line-height: 20px;
}

.comment-style {
  word-break: break-all;
}

.textarea{ 
  margin:10px 0px;
}

.bg-code{
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  body .el-container{
    width:100%;
    padding:5px !important;
  }
  .create-container .el-header{
    padding: 15px 0 !important;
  }
  .detail_title{
    text-align: center;
    display: none;
    font-size: 16px;
  }
  .detail-header-wrapper {
    flex-direction: column;
  }
  
  .detail-header-wrapper .detail_title{
    margin-left: 0 !important;
    font-size: 16px;
    display: block;
  }
  .detail_subtitle{
    width:100%;
    margin-right: 10px;
    justify-content: flex-end;
    align-items: center;
    // flex-direction: column;
  }
  .detail_subtitle-name{
    font-weight: bold;
  
  }

  .detail_subtitle-date{
    margin-left: 10px;
    font-size: 12px;
  }
  .detail_subtitle >span{
    margin: 0;
    font-size: 12px;
  }

  .el-main{
    padding:0;
    padding-top: 10px;
  }
  .el-main img{
    max-width: 100vw;
  }

  .detail_comment{
    
    min-height: 110px;
    padding:5px 10px;
  }
  .detail_comment_font{
    font-size: 14px;
    font-weight: 14px;
    display: flex;
    align-items: center;
  }
  .detail_comment_font>span{
    margin-left: 5px;
    font-weight: normal;
    font-size: 12px;
  }

  .board_comment{
    flex-wrap: wrap;
  }
  .board_comment button{
    padding:7px 15px !important; 
  }

  .button-wrapper{
    display: flex;
    
  }

  .el-footer{
    margin-top: 20px;
  }
  .button-wrapper>button{
    margin: 2px 0;
  }
  
}
</style>