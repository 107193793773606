<template>
  <el-container class="board-container">
    <el-header>
      <span>질문 게시판</span>

      <div class="tab-wrapper" v-if="isAdminRole">
        <el-tabs type="card" v-model="process" @tab-click="handleTapClick">
          <el-tab-pane label="전체" name="all"></el-tab-pane>
          <el-tab-pane label="New" name="0"></el-tab-pane>
          <el-tab-pane label="처리중" name="1"></el-tab-pane>
          <el-tab-pane label="완료" name="2"></el-tab-pane>
        </el-tabs>
        <el-input
          v-model="keyword"
          prefix-icon="el-icon-search"
          placeholder="제목 / 내용 / 이름 검색"
          class="search-input"
          @change="chagePage"
        ></el-input>
      </div>
    </el-header>
    <el-main>
      <el-table
        v-loading="loading"
        border
        v-if="data.length"
        @cell-click="detail"
        :data="data"
        empty-text="검색한 정보가 없습니다"
        :header-cell-style="cellStyle"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          prop="id"
          align="center"
          :width="$store.state.isMobile ? '70px' : '140px'"
          label="글 번호"
          :style="{ backgrondColor: 'rgb(252, 230, 190)' }"
        >
          <template slot-scope="scope">
            <div class="board-line">
              {{ scope.row.id }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="title"
          label="제목"
          :min-width="$store.state.isMobile ? '200px' : '400px'"
          header-align="center"
        >
          <template slot-scope="scope">
            
           <div class="qna-wrapper">
            <!-- TODO 집합교육/매핑된 경우에만 조회가능  -->
            
            <div
              class="board-line"
              v-if="
                scope.row.flag == 3 &&
                user.profile &&
                (isAdminRole || scope.row.created_by.id == user.profile.user.id)
              "
            >
              {{ scope.row.title
              }}
              
              
              <span
                v-if="
                  scope.row.board.length && getCommentCount(scope.row.board)
                "
                >[{{ getCommentCount(scope.row.board) }}]</span
              >🔒
              <el-tag
                type="primary"
                size="medium"
                v-if="scope.row.isNotice"
                style="margin: 0 5px"
                >공지사항</el-tag
              >

              <el-tag style="margin-right: 7px;"  v-if="scope.row?.problem?.id && scope.row?.contest?.id" type="success">{{scope.row?.problem?.title}}</el-tag>
              <el-tag
                type="primary"
                size="medium"
                v-if="isAdminRole && scope.row.process == 0"
                >new</el-tag
              >
              <el-tag
                type="warning"
                size="medium"
                v-if="isAdminRole && scope.row.process == 1"
                >처리중</el-tag
              >
              <el-tag
                type="success"
                size="medium"
                v-if="isAdminRole && scope.row.process == 2"
                >완료</el-tag
              >
            </div>
            <div class="board-line" v-else-if="scope.row.flag == 3">
              비공개 글입니다. 🔒
            </div>
            <div class="board-line" v-else>
              {{ scope.row.title }}
              <span
                class="board-line"
                v-if="
                  scope.row.board.length && getCommentCount(scope.row.board)
                "
                >[{{ getCommentCount(scope.row.board) }}]</span
              >

              <el-tag
                type="primary"
                size="medium"
                v-if="scope.row.isNotice"
                style="margin: 0 5px"
                >공지사항</el-tag
              >
              <el-tag style="margin-right: 7px;"  v-if="scope.row?.problem?.id && scope.row?.contest?.id" type="success">{{scope.row?.problem?.title}}</el-tag>
              <el-tag
                type="primary"
                size="medium"
                v-if="isAdminRole && scope.row.process == 0"
                >new</el-tag
              >
              <el-tag
                type="warning"
                size="medium"
                v-if="isAdminRole && scope.row.process == 1"
                >처리중</el-tag
              >
              <el-tag
                type="success"
                size="medium"
                v-if="isAdminRole && scope.row.process == 2"
                >완료</el-tag
              >
            </div>

           </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="real_name"
          label="작성자 (ID)"
          :min-width="$store.state.isMobile ? '100px' : '70px'"
          align="center"
        >
          <template slot-scope="scope">
            <div class="board-line">
              {{ scope.row["created_by"]["real_name"] }}
            </div>
            <div class="board-line">
              ({{ scope?.row["created_by"]["username"] }})
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="created_time"
          label="작성 날짜"
          :min-width="$store.state.isMobile ? '100px' : '70px'"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="$store.state.isMobile" class="board-line">
              {{
                scope.row.created_time &&
                toLocalTimeMobile(scope.row.created_time)
              }}
            </div>
            <div v-else>
              <div class="board-line">
                {{ scope.row.created_time && toLocal(scope.row.created_time) }}
              </div>
              <div class="board-line">
                ({{
                  scope.row.created_time && toLocalTime(scope.row.created_time)
                }})
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-main>

    <el-footer>
      <div class="page-wrapper">
        <el-pagination
          class="page"
          layout="prev, pager, next"
          :page-size="10"
          :current-page.sync="currentPage"
          @current-change="chagePage"
          :total="total"
        >
        </el-pagination>
        <el-button
          v-if="isAdminRole && !$route.params.contestID"
          :size="$store.state.isMobile ? 'mini' : 'medium'"
          type="primary"
          @click="write"
          class="write-btn"
          >글쓰기</el-button
        >
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import time from "@/utils/time";
import api from "@oj/api";
import { mapGetters, mapState } from "vuex";
export default {
  name: "qna",
  data() {
    return {
      total: 0,
      data: [],
      currentPage: this.$route.query.page ? +this.$route.query.page : 1,
      keyword: "",
      process: "all",
      loading: false,
    };
  },
  mounted() {
    this.getBoardList();
    this.getBoardNoticeList();
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["isSuperAdmin", "isAdminRole"]),
  },
  watch: {
    "$route.query": function () {
      this.page();
    },
  },
  methods: {
    toLocalTime(data) {
      const result = time.utcToLocal(data, "HH시 mm분");
      return result;
    },
    toLocal(data) {
      const result = time.utcToLocal(data, "YYYY년 M월 D일");

      return result;
    },
    toLocalTimeMobile(data) {
      const result = time.utcToLocal(data, "YYYY-MM-DD HH시 mm분");
      return result;
    },
    // toLocalMobile(data) {
    //   const result = time.utcToLocal(data, "YYYY년 M월 D일");

    //   return result;
    // },

    // 공지사항 호출하기

    async getBoardNoticeList() {
      const result = await api.getBoardNoticeList({
        company: this.$route.params?.board_name?.toUpperCase(),
      });
  
    },

    async getBoardList() {
      const noticeResponse = await api.getBoardNoticeList({
        company: this.$route.params?.board_name?.toUpperCase(),
      });

      const noticeResult = noticeResponse.data.data;
    

      try {
        const response = await api.getBoardList({
          limit: 10,
          offset: (this.currentPage - 1) * 10,
          keyword: this.keyword,
          company: this.$route.params?.board_name?.toUpperCase(),
          process: this.process,
          contest_id: this.$route?.params?.contestID,
        });

        if(!response.data.error){
          const data = Object.entries(response).find((el) => el[0] === "data");
          this.total = data[1]["data"]["total"];
          const result = data[1]["data"]["results"];
    
          this.data = [...noticeResult, ...result];
        }
        
      } catch (error) {
        this.data = [...noticeResult];
        
        
      }
    },
    async chagePage() {
      this.getBoardList();
      if (this.$route.params.contestID) {
        this.$router.push({
          path: `/enterprise/contest/${this.$route.params.board_name.toLowerCase()}/${
            this.$route.params.contestID
          }/qna`,
          query: { page: this.currentPage },
        });
      }else{
        this.$router.push({
        path: this.$route.params.board_name.toLowerCase(),
        query: { page: this.currentPage },
      });
      }
      
    },
    write() {
      // TODO contestID가 존재한다면
      if (this.$route.params.contestID) {
        this.$router.push({
          path: `/enterprise/contest/${this.$route.params.board_name.toLowerCase()}/${
            this.$route.params.contestID
          }/qna/create`,
          query: { page: this.currentPage },
        });
      } else {
        this.$router.push({
          path: `/qna/${this.$route.params.board_name.toLowerCase()}/create`,
          // query: { page: this.currentPage },
        });
      }
    },
    detail(id, column, cell, event) {
      if (
        this.isSuperAdmin ||
        (this.user.profile.user &&
          id.created_by.id === this.user.profile.user.id) ||
        (id.flag !== 3 && column.property === "title")
      ) {
        // router.params.contestId가 있을때

        if (this.$route.params.contestID) {
          this.$router.push({
            path: `/enterprise/contest/${this.$route.params.board_name.toLowerCase()}/${
              this.$route.params.contestID
            }/qna/${id.id}`
          });
        }else{
          this.$router.push({
          path: `/qna/${this.$route.params.board_name.toLowerCase()}/${id.id}`
        });
        }
        
      }
    },
    getCommentCount(commentList) {
      return commentList.filter((li) => li.flag !== 4).length;
    },
    page() {
      if (this.$route.query.page) {
        this.currentPage = +this.$route.query.page;
      } else {
        this.currentPage = 1;
      }
      this.getBoardList();
    },
    cellStyle() {
      return {
        background: "#409eff",
        color: "white",
      };
    },

    tableRowClassName({ row, rowIndex }) {

      if (row.isNotice) return "notice";
      return "";
    },
    handleTapClick() {
      this.currentPage = 1;
      this.chagePage();
      this.getBoardNoticeList();
    },
  },
};
</script>

<style lang="less" scoped>
.qna-wrapper{
  display:flex;
   align-items: center;
}
.contest-tag-wrapper{
  
  
}
.contest-tag-wrapper >*{
  margin-left: 8px;
  
}
.board-container {
  > * {
    width: 90%;
    margin: 0 auto;
  }
  .el-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 21px;
    font-weight: 500;
    padding-top: 19px;
    height: 100% !important;
    .search-input {
      max-width: 300px;
    }
  }
  margin: 0 auto;
  background: white;
}

.board-line {
  line-height: 18px;
}

.page-wrapper {
  display: flex;
  justify-content: right;
  align-items: center;
  position: relative;

  .page {
    padding: none;
  }
}

.tab-wrapper {
  display: flex;

  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 900px) {
  .board-container {
    > * {
      width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .board-container {
    .el-header {
      justify-content: center;
      flex-wrap: wrap;
      .search-input {
        margin-top: 10px;
      }
    }
    .el-main {
      padding: 0 !important;
      padding-top: 20px !important;
    }
    .el-footer {
      min-height: 75px !important;
      position: relative;
    }
    .page-wrapper {
      flex-direction: column;
      align-content: center;
    }
  }
  .tab-wrapper {
    flex-wrap: wrap;
  }
}
</style>

<style>
.el-table .notice {
  background: #2d8cf00f;
}

.el-tabs--card > .el-tabs__header {
  margin: 0 10px 0 0 !important;
}
</style>
