import api from "@oj/api";

const mixins = {
  async mounted() {
    if (this.$route.params["board_id"]) {
      this.index = this.$route.params["board_id"];
      await this.getNotice(this.$route.params["board_id"]);

      this.title = this.notice.notice.title;
      this.content = this.notice.notice.content;
      
      this.isNotice = this.notice.notice.isNotice;
      
      if (this.notice.notice.flag === 3) {
        this.checked = true;
      } else {
        this.checked = false;
      }

      if (
        !this.isSuperAdmin &&
        this.notice.notice.created_by.id !== this.user.profile.user.id
      ) {
        this.$router.push({
          path: `/announcement/${this.$route.params.board_name}`
        });
      }
    }
  },
  methods: {
    async write() {
      if (!this.isExistsTitle(this.title)) return;
      if (!this.isTitleTooLong(this.title)) return;
      if (!this.isExistsContent(this.content)) return;
      if (!this.isContentTooLong(this.content)) return;

      await api.postNotice(
        this.title,
        this.content,
        this.user.profile.user.id,
        
        // this.checked ? "secret" : "",
        this.checked === true ? "secret" : "normal",
        this.$route.params.board_name.toUpperCase(),
        this.isNotice
      );
      this.$router.push({
        path: `/announcement/${this.$route.params.board_name.toLowerCase()}`,
        query: { page: this.$route.query.page }
      });
    },
    update() {
      if (!this.isExistsTitle(this.title)) return;
      if (!this.isTitleTooLong(this.title)) return;
      if (!this.isExistsContent(this.content)) return;
      if (!this.isContentTooLong(this.content)) return;
      this.$Modal.confirm({
        content: "공지사항을 수정 하시겠습니까?",
        onOk: async () => {
          if (
            this.isSuperAdmin ||
            this.notice.notice.created_by.id === this.user.profile.user.id
          ) {
            const test = await api.putNotice(
              this.title,
              this.content,
              this.$route.params["board_id"],
              this.checked === true ? "secret" : "normal",
              this.isNotice
            );

            this.$router.push({
              path: `/announcement/${this.$route.params.board_name.toLowerCase()}/${
                this.$route.params["board_id"]
              }`
            });
          }
        },
        onCancel: () => {}
      });
    },
    isExistsTitle(title) {
      if (!title.length) {
        this.$error("제목을 입력해주세요");
        return;
      }
      return true;
    },
    isTitleTooLong(title) {
      if (title.length >= 100) {
        this.$error("제목은 100자 이내로 입력해주세요");
        return;
      }
      return true;
    },
    isExistsContent(content) {
      if (!content.length) {
        this.$error("내용을 입력해주세요");
        return;
      }
      return true;
    },
    isContentTooLong(content) {
      if (content.length > 5000000) {
        this.$error("내용은 5M 이상 업로드 할 수 없습니다.");
        return;
      }
      return true;
    }
  }
};

export default mixins;
