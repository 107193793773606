<template>
  <Panel shadow >
    <div slot="title">
      {{ title }}
    </div>
    <div slot="extra" v-if="listVisible">
      <Button v-if="isSuperAdmin && !isNews" type="info" @click="moveTutorCode"
        >강사님코드 작성/수정</Button
      >
    </div>

    <Table
      class="lecture-announce"
      @on-row-click="goRoute"
      :columns="columns"
      :data="announcements"
      key="list"
    ></Table>
  </Panel>

  
</template>

<script>
import moment from "moment";
import Simditor from "@/pages/admin/components/Simditor";
import api from "@oj/api";
import Pagination from "@oj/components/Pagination";
import { mapGetters, mapActions, mapState } from "vuex";
import BoardHighlight from "@/pages/oj/components/BoardHighlight";

export default {
  name: "Announcement",
  components: {
    Pagination,
    Simditor,
    BoardHighlight,
  },
  data() {
    return {
      limit: 10,
      total: 10,
      btnLoading: false,
      columns: [
        {
          title: "글 번호",
          width: "14%",
          align: "center",
          render: (h, params) => {
            return h("span", params.row.id);
          },
        },
        {
          title: "제목",
          width: "58%",
          align: "left",
          resizable: true,
          render: (h, params) => {
            
            return h("span", params.row.title, {
              
            });
          },
        },
        {
          title: "날짜",
          width: "30%",
          align: "center",
          render: (h, params) => {
            return h("div",{
              style: {
                  "padding" : 0,
                  
                },
            }, [
              h("div", moment(params.row.create_time).format("YYYY/MM/DD")),
              h("div", moment(params.row.create_time).format("HH:mm:ss")),
            ],);
          },
        },
      //  {
      //     title: "작성자",
      //     width: "14%",
      //     align: "center",
      //     render: (h, params) => {
      //       return h("span", params.row.created_by["real_name"], {
      //         style:{
      //           "padding": 0,
      //           "min-width":"30px"
      //         }
      //       });
      //     },
      //   },
      ],
      announcements: [],
      announcement: "",
      listVisible: true,
      isNews: true,
    };
  },
  mounted() {
    this.init();
    this.getProfile();
  },
  methods: {
    ...mapActions(["getProfile", "changeModalStatus"]),
    init() {
      if (this.isContest) {
        this.getContestAnnouncementList();
        this.isNews = false;
      } else {
        this.getAnnouncementList();
      }
    },
    getAnnouncementList(page = 1) {
      this.btnLoading = true;
      api.getAnnouncementList((page - 1) * this.limit, this.limit).then(
        (res) => {
          this.btnLoading = false;
          this.announcements = res.data.data.results;
          this.total = res.data.data.total;
        },
        () => {
          this.btnLoading = false;
        }
      );
    },
    getContestAnnouncementList() {
      this.btnLoading = true;
      api.getContestAnnouncementList(this.$route.params.contestID).then(
        (res) => {
          this.btnLoading = false;
          this.announcements = res.data.data;
        },
        () => {
          this.btnLoading = false;
        }
      );
    },
    moveTutorCode() {
      window.open(
        `/admin/contest/${this.$route.params.contestID}/announcement`
      );
    },
    goRoute(row) {
      this.$router.push({
        name: "contest-announcement-details",
        params : { announcementID: row.id },
      });
    },
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["user", "isAuthenticated", "isAdminRole", "isSuperAdmin"]),
    title() {
      if (this.listVisible) {
        return this.isContest
          ? "강사님 자료"
          : this.$i18n.t("m.News");
      } else {
        return this.announcement.title;
      }
    },
    isContest() {
      return !!this.$route.params.contestID;
    },
  },
};
</script>

<style  lang="less">
.ivu-table{
  padding: 0 10px !important;
  }
.iview-table-cell{
  padding:0;
}
.ivu-card-head{
  padding: 25px 16px 15px 16px !important;
}
.announcements-container {
  margin-top: -10px;
  margin-bottom: 10px;
  li {
    padding-top: 15px;
    list-style: none;
    padding-bottom: 15px;
    margin-left: 20px;
    font-size: 16px;
    border-bottom: 1px solid rgba(187, 187, 187, 0.5);
    &:last-child {
      border-bottom: none;
    }
    .flex-container {
      .title {
        flex: 1 1;
        text-align: left;
        padding-left: 10px;
        a.entry {
          color: #495060;
          &:hover {
            color: #2d8cf0;
            border-bottom: 1px solid #2d8cf0;
          }
        }
      }
      .creator {
        flex: none;
        width: 200px;
        text-align: center;
      }
      .date {
        flex: none;
        width: 200px;
        text-align: center;
      }
    }
  }
}

changeLocale .announcement-animate-enter-active {
  animation: fadeIn 1s;
}
.lecture-announce .ivu-table-header table th {
  text-align: center !important;
}
</style>
