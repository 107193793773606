// all routes here.
import {
  About,
  ACMRank,
  Announcements,
  ApplyResetPassword,
  FAQ,
  Java,
  Home,
  Logout,
  NotFound,
  OIRank,
  // Problem,
  EnterpriseProblem,
  ProblemList,
  ResetPassword,
  SubmissionDetails,
  SubmissionList,
  UserHome,
  Category
} from "@oj/views";



import EnterpriseSubmissionList from "@oj/views/submission/EnterpriseSubmissionList.vue"
import CategoryList from "@oj/views/problem/CategoryList.vue";

// 집합교육
// import ContestListMiracom from "@oj/views/contest/ContestListMiracom.vue";
// import ContestListSDS from "@oj/views/contest/ContestListSDS.vue";
// import ContestListMedison from "@oj/views/contest/ContestListMedison.vue";
// import ContestListMulticampus from "@oj/views/contest/ContestListMulticampus.vue";
// import ContestListHyosung from "@oj/views/contest/ContestListHyosung.vue";
// import ContestListSemes from "@oj/views/contest/ContestListSemes.vue";
// import ContestListSejong from "@oj/views/contest/ContestListSejong.vue";
// import ContestListSSAFY from "@oj/views/contest/ContestListSSAFY.vue";
// import ContestListS1 from "@oj/views/contest/ContestListS1.vue";

// 시험 후기
import MiracomTestReviewRead from "@oj/views/board/MiracomTestReviewRead.vue";
import MiracomTestReviewCreate from "@oj/views/board/MiracomTestReviewCreate.vue";
import MiracomTestReviewDetail from "@oj/views/board/MiracomTestReviewDetail.vue";


import ProblemStepLevelList from "@oj/views/problem-step/ProblemStepLevelList.vue";
import ProblemStepLevelDetailList from "@oj/views/problem-step/ProblemStepLevelDetailList.vue";

import QnaCreate from "@oj/views/qna/Create.vue"
import QnaDetail from "@oj/views/qna/Detail.vue"
import QnaRead from "@oj/views/qna/Read.vue"

import NoticeBoardCreate from "@oj/views/notice-board/Create.vue"
import NoticeBoardDetail from "@oj/views/notice-board/Detail.vue"
import NoticeBoardRead from "@oj/views/notice-board/Read.vue"

import ELearningCreate from "@oj/views/e-learning/Create.vue"
import ELearningDetail from "@oj/views/e-learning/Detail.vue"
import ELearningRead from "@oj/views/e-learning/Read.vue"

// admin
import {
  AdminAnnouncement,
  AdminConf,
  AdminContest,
  AdminContestList,
  AdminHome,
  AdminJudgeServer,
  AdminJudgeServerLast,
  AdminLogin,
  AdminProblem,
  AdminProblemList,
  AdminUser,
  AdminPruneTestCase,
  AdminDashboard,
  AdminProblemImportOrExport,
  AdminCategory
} from "@admin/views";

import AdminProblemStepCreate from "@admin/views/problem-step/ProblemStepCreate.vue";
import AdminProblemStepList from "@admin/views/problem-step/ProblemStepList.vue";
import AdminProblemStepLevelList from "@admin/views/problem-step/ProblemStepLevelList.vue";
import AdminProblemStepLevelCreate from "@admin/views/problem-step/ProblemStepLevelCreate.vue";
import AdminProblemStepLevelDetailList from "@admin/views/problem-step/ProblemStepLevelDetailList.vue";
import AdminProblemStepLevelDetailCreate from "@admin/views/problem-step/ProblemStepLevelDetailCreate.vue";
import AdminCreate from "@admin/views/mincoding_auth/secret-code/Create.vue";
import AdminList from "@admin/views/mincoding_auth/secret-code/List.vue";
import AdminAuthList from "@admin/views/mincoding_auth/auth/List.vue";
import AdminAuthCreate from "@admin/views/mincoding_auth/auth/Create.vue";
import AdminRecovery from "@admin/views/Recovery.vue";
import AdminProblemTag from "@admin/views/problem/Tag.vue";


import Favorite from "@oj/views/favorite/Favorite.vue";

import * as EnterpriseContest from "@oj/views/enterprise-contest";

import * as Contest from "@oj/views/contest";
import * as Setting from "@oj/views/setting";
// general
import AnnouncementDetail from "../views/general/AnnouncementDetail.vue";
import EnterpriseAnnouncementDetail from "../views/general/EnterpriseAnnouncementDetail.vue";
import EnterpriseAnnouncements from "../views/general/EnterpriseAnnouncements.vue";
import validation from "../components/validation"
import store from "../../../store";
import api from "../api";
import { CONTEST_STATUS, USER_TYPE, CONTEST_TYPE } from '@/utils/constants'

const authAdmin = (to, from, next) => {
  if (store.getters.isSuperAdmin || store.getters.isAdminRole) {
    next();
  }else{
    next('/')
  }
}

const authProblem = (to, from, next) => {
  // 검증 조건 3가지
  // 1. 이메일 검증
  // 2. 그룹 검증
  // 3. 개인 권한 검증

  // 그룹 검증은 무조건 이메일검증을 거치고 나서 두가지를 만족해야 라우터 이동이 가능하다.

  // 이메일 검증

  let isValidEmail = false;
  let isValidAuth = false;
  const profile = store.getters.profile;
  // console.log("껌증 로직")
  // console.log(profile);

  // sds이면서 email @zigbang.com
  // console.log(profile.user.email);

  if (profile && profile.user.email && profile["mincoding_auth"]) {
  
    if (!profile["mincoding_auth"]["verify_email"]) {
      isValidEmail = true;
    }
    // console.log(profile["mincoding_auth"]["verify_email"])

    if (profile["mincoding_auth"]["verify_email"]) {
      if (
        profile.user.email.indexOf(profile["mincoding_auth"]["verify_email"]) >
        -1
      ) {
        isValidEmail = true;
      }

      if (
        profile.user.email.indexOf("@zigbang.com")  >
        -1 && profile.github.toLowerCase() === "sds"
      ) {
        isValidEmail = true;
      }



    }
  }


  // 그룹 권한 인증

  let mincodingAuth = [];
  if (
    profile["mincoding_auth"] &&
    profile["mincoding_auth"]["secret_code"].length
  ) {
    mincodingAuth = profile["mincoding_auth"]["secret_code"].map(
      li => li.problem_step.id
    );

    if(mincodingAuth.length && mincodingAuth.includes(Number(to.params.step_id)) && isValidEmail){
      isValidAuth = true;
    }
  }
  // console.log(mincodingAuth);
  // console.log(to.params);
  // 그룹권한은 이메일 권한도 같이 있어야한다
  

  // 유저 권한 인증
  let userAuth = [];
  if (profile["user_auth"] && profile["user_auth"].length) {
    userAuth = profile["user_auth"].map(li => li.problem_step.id);
    if(userAuth.length && userAuth.includes(Number(to.params.step_id))){
      isValidAuth = true;
    }
  }
  // console.log(userAuth);
  // console.log()

  

  if (store.getters.isSuperAdmin || store.getters.isAdminRole) {
    next();
  } else if (isValidAuth) {
    next();
  } else {
    alert("권한이 존재하지 않습니다.");
    window.location.href = window.location.origin
    next("/");
  }
  // next();
};

const secretAuthQna = (to, from, next) => {

  // 관리자이면 걍 next()
  // console.log(store.state.user.profile.user.admin_type);
  const adminType = store.state.user.profile.user.admin_type;
  if(adminType === "Super Admin" || adminType.indexOf("Admin") > -1){
    next();
  }
  // 여기서 mincoding_auth가 존재하고 또 지금 이동하는 곳의 router_name이랑
  // 일치해야지만 보내줄수 있다.
  // 
  // store.state.user.profile.mincoding_auth.router_name 이랑 일치 해야한다!
  else if(store.state.user.profile.mincoding_auth && store.state.user.profile.mincoding_auth.router_name.toLowerCase() === to.params.board_name.toLowerCase()){
    // 위 조건을 만족한 채로 announce에 대한 권한

    const isValidEmail = validation.isValidEmail(store.state.user);
    const isValidUserName = validation.isValidUsername(store.state.user);
    if(isValidEmail && isValidUserName && store.state.user.profile.mincoding_auth.is_qna_visible){
      next();
    }else{
      alert("권한이 없습니다.");
      next('/')
    }

  }else{
    alert("권한이 없습니다.");
    next('/');
  }
  
  
}
  

const secretAuthAnnounce = (to, from, next) => {
  // 관리자이면 걍 next()
  // console.log(store.state.user.profile.user.admin_type);
  const adminType = store.state.user.profile.user.admin_type

  if(adminType === "Super Admin" || adminType.indexOf("Admin") > -1){
    next();
  }
  // 여기서 mincoding_auth가 존재하고 또 지금 이동하는 곳의 router_name이랑
  // 일치해야지만 보내줄수 있다.
  // 
  // store.state.user.profile.mincoding_auth.router_name 이랑 일치 해야한다!
  else if(store.state.user.profile.mincoding_auth &&  store.state.user.profile.mincoding_auth.router_name.toLowerCase() === to.params.board_name.toLowerCase()){
    // 위 조건을 만족한 채로 announce에 대한 권한
    const isValidEmail = validation.isValidEmail(store.state.user);
    const isValidUserName = validation.isValidUsername(store.state.user);
    if(isValidEmail && isValidUserName && store.state.user.profile.mincoding_auth.is_notice_visible){
      next();
    }else{
      alert("권한이없습니다");
      next('/')
    }

  }else{
    alert("권한이없습니다");
    next('/');

  }
  
}




const superAdmin = (to, from, next) => {
  // 관리자이면 걍 next()
  // console.log(store.state.user.profile.user.admin_type);
  const adminType = store.state.user.profile.user.admin_type

  if(adminType === "Super Admin" || adminType.indexOf("Admin") > -1){
    next();
  }
  else{
    alert("권한이없습니다");
    next('/');
  }
}

const secretAuthELearning = (to, from, next) => {
  // 관리자이면 걍 next()
  // console.log(store.state.user.profile.user.admin_type);
  const adminType = store.state.user.profile.user.admin_type

  if(adminType === "Super Admin" || adminType.indexOf("Admin") > -1){
    next();
  }
  // 여기서 mincoding_auth가 존재하고 또 지금 이동하는 곳의 router_name이랑
  // 일치해야지만 보내줄수 있다.
  // 
  // store.state.user.profile.mincoding_auth.router_name 이랑 일치 해야한다!
  else if(store.state.user.profile.mincoding_auth &&  store.state.user.profile.mincoding_auth.router_name.toLowerCase() === to.params.company.toLowerCase()){
    // 위 조건을 만족한 채로 announce에 대한 권한
    const isValidEmail = validation.isValidEmail(store.state.user);
    const isValidUserName = validation.isValidUsername(store.state.user);
    if(isValidEmail && isValidUserName && store.state.user.profile.mincoding_auth.is_e_learning_visible){
      next();
    }else{
      next('/')
    }

  }else{
    next('/');

  }
  
}

const checkContestAuthorization = (to, from, next) => {
  const adminType = store.state.user.profile.user.admin_type;
  
  if(adminType === "Super Admin" || adminType.indexOf("Admin") > -1){
    next();
    return;
  }

  store.dispatch("getContest").then(() => {

  if(store?.getters['contestStatus'] == -1){
    
    const routerName = store.state.user.profile.mincoding_auth.name;
    next(`/enterprise/contest/${routerName}`)
    alert("종료된 강의입니다.");
    return;
  }

  next();  
  
  return;
  })
  
  

}


export default [
  {
    name: "home",
    path: "/",
    meta: { title: "Home" },
    component: Home
  },
  {
    name: "category",
    path: "/category",
    meta: { title: "Category" },
    component: Category
  },
  {
    name: "category-list",
    path: "/category-list",
    meta: { title: "Category List" },
    component: CategoryList
  },
  {
    name: "logout",
    path: "/logout",
    meta: { title: "Logout" },
    component: Logout
  },
  {
    name: "apply-reset-password",
    path: "/apply-reset-password",
    meta: { title: "Apply Reset Password" },
    component: ApplyResetPassword
  },
  {
    name: "reset-password",
    path: "/reset-password/:token",
    meta: { title: "Reset Password" },
    component: ResetPassword
  },
  {
    name: "problem-list",
    path: "/problem",
    meta: { title: "Problem List" },
    component: AdminProblemList,
    beforeEnter: superAdmin
  },
  // {
  //   name: "problem-details",
  // beforeEnter: (to, from, next) => {
  //   next();
  // },
  //   path: "/problem/:problemID",
  //   meta: { title: "Problem Details" },
  //   component: Problem
  // },
  {
    name: "problem-details",
    // beforeEnter: authAdmin,
    path: "/problem/:problemID",
    meta: { title: "Problem Details" },
    component: EnterpriseProblem
    // component: Problem
  },
  {
    name: "submission-list",
    path: "/status",
    meta: { title: "Submission List" },
    component: EnterpriseSubmissionList
    // component: SubmissionList
  },
  {
    name: "submission-details",
    path: "/status/:id/",
    meta: { title: "Submission Details" },
    component: SubmissionDetails
  },
  // {
  //   name: "contest-list",
  //   path: "/contest",
  //   meta: { title: "Contest List" },
  //   component: Contest.ContestList
  // },
  // {
  //   name: "contest-miracomlist",
  //   path: "/contest-miracom",
  //   meta: { title: "Contest MiracomList" },
  //   component: ContestListMiracom
  // },
  // // 0316 SDS추가
  // {
  //   name: "contest-sdslist",
  //   path: "/contest-sds",
  //   meta: { title: "Contest SdsList" },
  //   component: ContestListSDS
  // },
  // // medison
  // {
  //   name: "contest-medisonlist",
  //   path: "/contest-medison",
  //   meta: { title: "Contest medisonList" },
  //   component: ContestListMedison
  // },
  // // Multicampus
  // {
  //   name: "contest-multicampuslist",
  //   path: "/contest-multicampus",
  //   meta: { title: "Contest multicampusList" },
  //   component: ContestListMulticampus
  // },
  // Hyosung
  // {
  //   name: "contest-hyosunglist",
  //   path: "/contest-hyosung",
  //   meta: { title: "Contest hyosunglist" },
  //   component: ContestListHyosung
  // },
  // Semes
  // {
  //   name: "contest-semeslist",
  //   path: "/contest-semes",
  //   meta: { title: "Contest semeslist" },
  //   component: ContestListSemes
  // },
  // // Sejong
  // {
  //   name: "contest-sejonglist",
  //   path: "/contest-sejong",
  //   meta: { title: "Contest sejonglist" },
  //   component: ContestListSejong
  // },
  // // SSAFY
  // {
  //   name: "contest-ssafylist",
  //   path: "/contest-ssafy",
  //   meta: { title: "Contest ssafylist" },
  //   component: ContestListSSAFY
  // },

  // {
  //   name: "contest-s1",
  //   path: "/contest-s1",
  //   meta: { title: "Contest ssafylist" },
  //   component: ContestListS1
  // },

  {
    name: "contest-details",
    path: "/contest/:contestID/",
    component: Contest.ContestDetails,
    meta: { title: "Contest Details" },
    children: [
      {
        name: "contest-submission-list",
        path: "submissions",
        component: SubmissionList
      },
      {
        name: "contest-problem-list",
        path: "problems",
        component: Contest.ContestProblemList
      },
      {
        name: "contest-problem-details",
        path: "problem/:problemID/",
        component: EnterpriseProblem
      },
      {
        name: "contest-announcement-list",
        path: "announcements",
        component: Announcements,
      },
      {
        name: "contest-announcement-details",
        path: "announcements/:announcementID/",
        component: AnnouncementDetail,
      },
      {
        name: "contest-rank",
        path: "rank",
        component: Contest.ContestRank
      },
      {
        name: "acm-helper",
        path: "helper",
        component: Contest.ACMContestHelper
      }
    ]
  },

    {
    name: "enterprise-contest-list",
    path: "/enterprise/contest/:board_name",
    meta: { title: "enterprise education" },
    component: EnterpriseContest.EnterpriseContestLists
  },
  
  {
    name: "enterprise-contest-details",
    path: "/enterprise/contest/:board_name/:contestID/",
    component: EnterpriseContest.EnterpriseContestDetails,
    meta: { title: "Contest Details" },
    children: [
      {
        beforeEnter: checkContestAuthorization,
        name: "enterprise-contest-submission-list",
        path: "submissions",
        component: EnterpriseSubmissionList
      },
      {
        beforeEnter: checkContestAuthorization,
        name: "enterprise-contest-problem-list",
        path: "problems",
        component: EnterpriseContest.ContestProblemList
      },
      {
        beforeEnter: checkContestAuthorization,
        name: "enterprise-contest-problem-details",
        path: "problem/:problemID/",
        component: EnterpriseProblem
      },
      {
        beforeEnter: checkContestAuthorization,
        name: "enterprise-contest-announcement-list",
        path: "announcements",
        component: EnterpriseAnnouncements,
      },
      {
        beforeEnter: checkContestAuthorization,
        name: "enterprise-contest-announcement-details",
        path: "announcements/:announcementID/",
        component: EnterpriseAnnouncementDetail,
      },
      {
        beforeEnter: checkContestAuthorization,
        name: "enterprise-contest-rank",
        path: "rank",
        component: EnterpriseContest.ContestRank
      },
      {
        beforeEnter: checkContestAuthorization,
        name: "enterprise-qna",
        path: "qna",
        component: QnaRead
      },
      {
        beforeEnter: checkContestAuthorization,
        name: "enterprise-qna-create",
        path: "qna/create",
        component: QnaCreate
      },
      {
        beforeEnter: checkContestAuthorization,
        name: "enterprise-qna-detail",
        path: "qna/:board_id",
        component: QnaDetail
      },
      
      {
        beforeEnter: checkContestAuthorization,
        name: "enterprise-qna-edit",
        path: "qna/:board_id/edit",
        component: QnaCreate
      },
      {
        beforeEnter: checkContestAuthorization,
        name: "enterprise-acm-helper",
        path: "helper",
        component: EnterpriseContest.ACMContestHelper
      }
    ]
  },
  {
    name: "acm-rank",
    path: "/acm-rank",
    meta: { title: "ACM Rankings" },
    component: ACMRank
  },
  {
    name: "oi-rank",
    path: "/oi-rank",
    meta: { title: "OI Rankings" },
    component: OIRank
  },
  {
    name: "user-home",
    path: "/user-home",
    component: UserHome,
    meta: { requiresAuth: true, title: "User Home" }
  },
  {
    path: "/setting",
    component: Setting.Settings,
    children: [
      {
        name: "default-setting",
        path: "",
        meta: { requiresAuth: true, title: "Default Settings" },
        component: Setting.ProfileSetting
      },
      {
        name: "profile-setting",
        path: "profile",
        meta: { requiresAuth: true, title: "Profile Settings" },
        component: Setting.ProfileSetting
      },
      {
        name: "account-setting",
        path: "account",
        meta: { requiresAuth: true, title: "Account Settings" },
        component: Setting.AccountSetting
      },
      {
        name: "security-setting",
        path: "security",
        meta: { requiresAuth: true, title: "Security Settings" },
        component: Setting.SecuritySetting
      }
    ]
  },
  {
    path: "/about",
    name: "about",
    meta: { title: "About" },
    component: About
  },
  {
    path: "/FAQ",
    name: "FAQ",
    meta: { title: "FAQ" },
    component: FAQ
  },
  {
    path: "/qna/:board_name",
    name:"qna_list",
    beforeEnter:secretAuthQna,
    meta: {requiresAuth: true, title: "qna"},
    component: QnaRead
  },
  {
    path: "/qna/:board_name/create",
    beforeEnter:superAdmin,
    name:"qna-create",
    meta: {requiresAuth: true, title: "qna"},
    component: QnaCreate
  },
  {
    path: "/qna/:board_name/:board_id",
    beforeEnter:secretAuthQna,
    name:"qna_detail",
    meta: {requiresAuth: true, title: "qna"},
    component: QnaDetail
  },

  {
    path: "/qna/:board_name/:board_id/edit",
    beforeEnter:superAdmin,
    name:"qna-edit",
    meta: {requiresAuth: true, title: "qna"},
    component: QnaCreate
  },

  {
    path: "/announcement/:board_name",
    beforeEnter:secretAuthAnnounce,
    name:"announcement_list",
    meta: {requiresAuth: true, title: "announcement"},
    component: NoticeBoardRead
  },
  {
    path: "/announcement/:board_name/create",
    beforeEnter:secretAuthAnnounce,
    name:"announcement-create",
    meta: {requiresAuth: true, title: "announcement"},
    component: NoticeBoardCreate
  },
  {
    path: "/announcement/:board_name/:board_id",
    beforeEnter:secretAuthAnnounce,
    name:"announcement_list_detail",
    meta: {requiresAuth: true, title: "announcement"},
    component: NoticeBoardDetail
  },

  {
    path: "/announcement/:board_name/:board_id/edit",
    beforeEnter:secretAuthAnnounce,
    name:"announcement-edit",
    meta: {requiresAuth: true, title: "announcement"},
    component: NoticeBoardCreate
  },

  {
    path: "/e_learnings/:board_name/:company",
    beforeEnter:secretAuthELearning,
    name:"e-learning_list",
    meta: {requiresAuth: true, title: "e_learnings"},
    component: ELearningRead
  },
  {
    path: "/e_learnings/:board_name/:company/create",
    beforeEnter:secretAuthELearning,
    name:"e-learning-create",
    meta: {requiresAuth: true, title: "e_learnings"},
    component: ELearningCreate
  },
  {
    path: "/e_learnings/:board_name/:company/:board_id",
    beforeEnter:secretAuthELearning,
    name:"e-learning_list_detail",
    meta: {requiresAuth: true, title: "e_learnings"},
    component: ELearningDetail
  },

  {
    path: "/e_learnings/:board_name/:company/:board_id/edit",
    beforeEnter:secretAuthELearning,
    name:"e-learning-edit",
    meta: {requiresAuth: true, title: "e_learnings"},
    component: ELearningCreate
  },

  {
    path: "/java",
    name: "java",
    meta: { title: "Java" },
    component: Java
  },

  {
    name: "problem-detail",
    beforeEnter: authProblem,
    path: "/problem-step/:step_id/level/:level_id/detail/:problemID",
    meta: { requiresAuth: true, title: "Problem Details" },
    component: EnterpriseProblem
  },
  {
    path: "/problem-step/:step_id/level/:level_id/detail",
    beforeEnter: authProblem,
    name: "ProblemStepLevelDetailList",
    meta: { requiresAuth: true, title: "ProblemStepLevelDetailList" },
    component: ProblemStepLevelDetailList
  },
  {
    path: "/problem-step/:step_id/level",
    beforeEnter: authProblem,
    name: "ProblemStepLevelList",
    meta: { requiresAuth: true, title: "ProblemStepLevelList" },
    component: ProblemStepLevelList
  },
  {
    path: "/favorite",
    name: "Favorite",
    meta: { title: "Favorite" },
    component: Favorite
  },
  {
    path: "/test-review-board-miracom",
    name :"test-review-board-miracom",
    meta: {requiresAuth: true, title : "TestReviewMiracom"},
    component: MiracomTestReviewRead
  },
  {
    path: "/test-review-board-miracom/:board_id",
    name :"test-review-board-miracom-detail",
    meta: {requiresAuth: true, title : "TestReviewMiracom"},
    component: MiracomTestReviewDetail
  },
  {
    path: "/test-review-miracom-create/:board_id?",
    name :"test-review-miracom-detail",
    meta: {title : "TestReviewMiracom"},
    component: MiracomTestReviewCreate
  },

  // admin
  {
    path: "/admin",
    component: AdminHome,
    beforeEnter: superAdmin,
    children: [
      {
        path: "/admin",
        name: "dashboard",
        component: AdminDashboard
      },
      {
        path: "/admin/announcement",
        name: "announcement",
        component: AdminAnnouncement
      },
      {
        path: "/admin/user",
        name: "user",
        component: AdminUser
      },
      {
        path: "/admin/conf",
        name: "conf",
        component: AdminConf
      },
      {
        path: "/admin/judge-server",
        name: "judge-server",
        component: AdminJudgeServer
      },
      {
        path: "/admin/judge-server-last",
        name: "judge-server",
        component: AdminJudgeServerLast
      },
      {
        path: "/admin/prune-test-case",
        name: "prune-test-case",
        component: AdminPruneTestCase
      },
      {
        path: "/admin/problems",
        name: "problem-list",
        component: AdminProblemList
      },
      {
        path: "/admin/problem/create",
        name: "create-problem",
        component: AdminProblem
      },
      {
        path: "/admin/problem/edit/:problemId",
        name: "edit-problem",
        component: AdminProblem
      },
      {
        path: "/admin/problem/batch_ops",
        name: "problem_batch_ops",
        component: AdminProblemImportOrExport
      },
      {
        path: "/admin/contest/create",
        name: "create-contest",
        component: AdminContest
      },
      {
        path: "/admin/contest",
        name: "contest-list",
        component: AdminContestList
      },
      {
        path: "/admin/contest/:contestId/edit",
        name: "edit-contest",
        component: AdminContest
      },
      {
        path: "/admin/contest/:contestId/announcement",
        name: "admin-contest-announcement",
        component: AdminAnnouncement
      },
      {
        path: "/admin/contest/:contestId/problems",
        name: "admin-contest-problem-list",
        component: AdminProblemList
      },
      {
        path: "/admin/contest/:contestId/problem/create",
        name: "create-contest-problem",
        component: AdminProblem
      },
      {
        path: "/admin/contest/:contestId/problem/:problemId/edit",
        name: "edit-contest-problem",
        component: AdminProblem
      },
      {
        path: "/admin/category",
        name: "category",
        component: AdminCategory
      },
      // level별 문제만들기
      {
        path: "/admin/problem-steps/create",
        name: "create-level-problem",
        component: AdminProblemStepCreate
      },
      {
        // /problem-steps/:problem_step_id/create
        path: "/admin/problem-steps/:problem_step_id/edit",
        name: "problem_step_edit",
        component: AdminProblemStepCreate
      },
      {
        path: "/admin/level-problem/edit/:problemId",
        name: "edit-level-problem",
        component: AdminProblemStepCreate
      },
      {
        path: "/admin/problem-steps",
        name: "problem-steps",
        component: AdminProblemStepList
      },
      {
        path: "/admin/problem-steps/:problem_step_id",
        name: "problem_step_level",
        component: AdminProblemStepLevelList
      },
      {
        path: "/admin/problem-steps/:problem_step_id/create",
        name: "problem_step_level_create",
        component: AdminProblemStepLevelCreate
      },

      {
        path:
          "/admin/problem-steps/:problem_step_id/level/:problem_step_level_id/edit",
        name: "problem_step_level_edit",
        component: AdminProblemStepLevelCreate
      },

      {
        path:
          "/admin/problem-steps/:problem_step_id/level/:problem_step_level_id/detail",
        name: "problem_step_level_detail",
        component: AdminProblemStepLevelDetailList
      },
      {
        path:
          "/admin/problem-steps/:problem_step_id/level/:problem_step_level_id/detail/create",
        name: "problem_step_level_detail_create",
        component: AdminProblemStepLevelDetailCreate
      },
      {
        path:
          "/admin/problem-steps/:problem_step_id/level/:problem_step_level_id/detail/:problem_step_level_detail_id/edit",
        name: "problem_step_level_detail_edit",
        component: AdminProblemStepLevelDetailCreate
      },
      { 
        path: "/admin/secret_code_auth/create",
        name: "secret_code_auth_create",
        component: AdminCreate
      },
      { 
        path: "/admin/secret_code_auth/edit/:id",
        name: "secret_code_auth_edit",
        component: AdminCreate
      },
      {
        path: "/admin/secret_code_auth",
        name: "secret_code_auth",
        component: AdminList
      },
      {
        path: "/admin/mincoding_auth/create",
        name: "mincoding_auth/create",
        component:AdminAuthCreate
      },
      {
        path: "/admin/mincoding_auth/edit/:id",
        name: "mincoding_auth/edit",
        component:AdminAuthCreate
      },
      {
        path: "/admin/mincoding_auth",
        name: "mincoding_auth",
        component:AdminAuthList
      },
      {
        path: "/admin/recovery",
        name: "recovery",
        component:AdminRecovery
      },
      {
        path: "/admin/problem/tag",
        name: "tag",
        component:AdminProblemTag
      }
    ]
  },
  {
    path: "*",
    meta: { title: "404" },
    component: NotFound
  }
];
