<template>
  <el-container class="board-container">
    <el-header>
      <span>시험후기</span>
      <el-input
        v-model="keyword"
        prefix-icon="el-icon-search"
        placeholder="제목 / 내용 / 이름 검색"
        class="search-input"
        @change="chagePage"
      ></el-input>
    </el-header>
    <el-main>
      <el-table
        border
        @cell-click="detail"
        :data="data"
        empty-text="검색한 정보가 없습니다"
        :header-cell-style="cellStyle"
        row-class-name="myrow"
      >
        <el-table-column
          prop="id"
          align="center"
          :width="$store.state.isMobile ? '70px' :'140px'"
          label="글 번호"
          :style="{ backgrondColor: 'rgb(252, 230, 190)' }"
        >
          <template slot-scope="scope">
            <div class="board-line">
              {{ scope.row.id }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="title"
          label="제목"
          :min-width="$store.state.isMobile ? '200px' :'400px'"
          header-align="center"
        >
          <template slot-scope="scope">
            <div
              class="board-line"
              v-if="
                scope.row.flag == 3 &&
                user.profile &&
                (isSuperAdmin ||
                  scope.row.created_by.id == user.profile.user.id)
              "
            >
              {{ scope.row.title
              }}<span
                v-if="
                  scope.row.board.length && getCommentCount(scope.row.board)
                "
                >[{{ getCommentCount(scope.row.board) }}]</span
              >🔒
            </div>
            <div class="board-line" v-else-if="scope.row.flag == 3">
              비공개 글입니다. 🔒
            </div>
            <div class="board-line" v-else>
              {{ scope.row.title }}
              <span
                class="board-line"
                v-if="
                  scope.row.board.length && getCommentCount(scope.row.board)
                "
                >[{{ getCommentCount(scope.row.board) }}]</span
              >
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="real_name"
          label="작성자 (ID)"
          :min-width="$store.state.isMobile ? '100px' :'70px'"
          align="center"
        >
          <template slot-scope="scope">
            <div class="board-line">
              {{ scope.row["created_by"]["real_name"] }}
            </div>
            <div class="board-line">
              ({{ scope.row["created_by"]["username"] }})
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="created_time"
          label="작성 날짜"
          :min-width="$store.state.isMobile ? '100px' :'70px'"
          align="center"
        >
          <template slot-scope="scope">
            <div class="board-line">
              {{ scope.row.created_time && toLocal(scope.row.created_time) }}
            </div>
            <div class="board-line">
              ({{
                scope.row.created_time && toLocalTime(scope.row.created_time)
              }})
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-main>

    <el-footer>
      <div class="page-wrapper">
        <el-pagination
          class="page"
          layout="prev, pager, next"
          :page-size="10"
          :current-page.sync="currentPage"
          @current-change="chagePage"
          :total="total"
        >
        </el-pagination>
        <el-button :size="$store.state.isMobile ?'mini':'medium'" type="primary" @click="write" class="write-btn"
          >글쓰기</el-button
        >
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import time from "@/utils/time";
import api from "@oj/api";
import { mapGetters, mapState } from "vuex";
export default {
  name: "Read_miracom",
  data() {
    return {
      total: 0,
      data: [],
      currentPage: this.$route.query.page ? +this.$route.query.page : 1,
      keyword: "",
    };
  },
  async mounted() {
    await this.getBoardList();
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["isSuperAdmin"]),
  },
  watch: {
    "$route.query": function () {
      this.page();
    },
  },
  methods: {
    toLocalTime(data) {
      const result = time.utcToLocal(data, "HH시 mm분");
      return result;
    },
    toLocal(data) {
      const result = time.utcToLocal(data, "YYYY년 M월 D일");

      return result;
    },
    async getBoardList() {
      const response = await api.getBoardList({
        limit: 10,
        offset: (this.currentPage - 1) * 10,
        keyword: this.keyword,
        company: "TEST-REIVEW-MIRACOM",
      });
      const data = Object.entries(response).find((el) => el[0] === "data");
      this.total = data[1]["data"]["total"];
      const result = data[1]["data"]["results"];
      this.data = result;
    },
    async chagePage() {
      this.getBoardList();
      this.$router.push({
        path: `test-review-board-miracom`,
        query: { page: this.currentPage },
      });
    },
    write() {
      this.$router.push({
        path: "test-review-miracom-create",
        query: { page: this.currentPage },
      });
    },
    detail(id, column, cell, event) {
      if (
        this.isSuperAdmin ||
        (this.user.profile.user &&
          id.created_by.id === this.user.profile.user.id) ||
        (id.flag !== 3 && column.property === "title")
      ) {
        this.$router.push({
          path: `/test-review-board-miracom/${id.id}`,
          query: { page: this.currentPage },
        });
      }
    },
    getCommentCount(commentList) {
      return commentList.filter((li) => li.flag !== 4).length;
    },
    page() {
      if (this.$route.query.page) {
        this.currentPage = +this.$route.query.page;
      } else {
        this.currentPage = 1;
      }
      this.getBoardList();
    },
    cellStyle() {
      return {
        background: "#409eff",
        color: "white",
      };
    },
  },
};
</script>

<style lang="less" scoped>
.board-container {
  > * {
    width: 90%;
    margin: 0 auto;
  }
  .el-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 21px;
    font-weight: 500;
    padding-top: 19px;
    height: 100% !important;
    .search-input {
      max-width: 300px;
    }
  }
  margin: 0 auto;
  background: white;
}

.board-line {
  line-height: 18px;
}

.page-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .page {
    padding: none;
  }
  .write-btn {
    position: absolute;
    right: 0;
  }
}

@media screen and (max-width: 900px) {
  .board-container {
    > * {
      width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .board-container {
    .el-header {
      justify-content: center;
      flex-wrap: wrap;
      .search-input {
        margin-top: 10px;
      }
    }
    .el-main{
      padding:0 !important;
      padding-top:20px !important;
    }
    .el-footer{
        min-height: 75px !important;
        position: relative;
        
    }
    .page-wrapper {
      // position: relative;
      align-items: center;
      // flex-wrap: wrap;
      // position: absolute;
      top: 12%;
      left: 0;
      .write-btn {
        position: absolute;
        top: 30px;
      }
    }
  }
}
</style>
