import { render, staticRenderFns } from "./Read.vue?vue&type=template&id=3800a821&scoped=true"
import script from "./Read.vue?vue&type=script&lang=js"
export * from "./Read.vue?vue&type=script&lang=js"
import style0 from "./Read.vue?vue&type=style&index=0&id=3800a821&prod&lang=less&scoped=true"
import style1 from "./Read.vue?vue&type=style&index=1&id=3800a821&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3800a821",
  null
  
)

export default component.exports