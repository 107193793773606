<template>
  <el-container>
    <el-header>
      <div class="detail_title">게시글 작성</div>
      <el-input v-model="title" placeholder="제목을 입력해주세요" />
      <el-checkbox v-model="checked" class="secret-checkbox"
        >비밀글로 작성하기</el-checkbox
      >
    </el-header>
    <el-main>
      <VueCkEditor :data="content" @setContent="setContent"></VueCkEditor>
      <!-- <Simditor v-model="content" placeholder=""></Simditor> -->

      <div style="margin-top: 50px"></div>
      <el-button v-if="index" @click="update">수정</el-button>
      <el-button v-else @click="write">작성</el-button>
    </el-main>
  </el-container>
</template>

<script>
import VueCkEditor from "@/pages/admin/components/VueCkEditor.vue";
import Simditor from "@/pages/admin/components/Simditor";
import mixins from "./createMixins";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  mixins:[mixins],
  name: "TestReviewMiracomCreate",
  components: {
    Simditor,
    VueCkEditor
  },
  created() {

  },
  data() {
    const index = this.$route.params.contentId;
    return {
      data: "",
      index: "",
      title: "",
      content: "",
      route: "",
      checked: "",
      company: "TEST-REIVEW-MIRACOM",
      routeBoard: "test-review-board-miracom"
    };
  },
  computed: {
    ...mapState(["board", "user"]),
    ...mapGetters(["isSuperAdmin"])
  },
  methods: {
    ...mapActions(["getBoard"]),
    setContent(e) {
      this.content = e;
    },
  }
};
</script>

<style lang="less" scoped>
.el-container {
  background:white;
  margin:0px auto;
  padding:0px 80px;
  .el-header {
    background: white;
    padding: 20px 40px;
    height: 100% !important;
  }
  .el-main{
    width:100%;
  }

  .detail_title {
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .secret-checkbox {
    display: block;
    text-align: right;
    margin-top: 10px;
  }
  .btns{
    margin-top:20px;
    display: flex;
    .el-button{
      margin-left: auto;
    }
  }
}
</style>
