var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"white"}},[_c('el-container',{staticClass:"create-container"},[_c('el-header',[_c('div'),_c('div',{staticClass:"detail_title"},[_c('span',[_vm._v("질문게시판")]),(_vm.board['board'].contest?.id)?_c('el-tag',{staticClass:"detail_title_tag",attrs:{"type":"primary"},on:{"click":() => _vm.moveRoute('contest')}},[_vm._v(_vm._s(_vm.board["board"].contest?.title))]):_vm._e(),(_vm.board['board'].problem?.id)?_c('el-tag',{staticClass:"detail_title_tag",attrs:{"type":"success"},on:{"click":() => _vm.moveRoute('problem')}},[_vm._v(_vm._s(_vm.board["board"].problem?.title))]):_vm._e()],1),_c('div',{staticClass:"detail-header-wrapper"},[_c('div'),(_vm.board['board'].flag === 3)?_c('div',{staticClass:"detail_title",staticStyle:{"text-align":"center","margin-left":"15%"}},[_vm._v(" "+_vm._s(_vm.board["board"].title)+" 🔒 ")]):_c('div',{staticClass:"detail_title",staticStyle:{"text-align":"center","margin-left":"15%"}},[_vm._v(" "+_vm._s(_vm.board["board"].title)+" ")]),(_vm.$store.state.isMobile)?_c('div',{staticClass:"detail_subtitle"},[_c('div',{staticClass:"detail_subtitle-name"},[_vm._v(" "+_vm._s(_vm.board["board"].real_name)+" / "+_vm._s(_vm.board["board"].created_by.username)+" ")]),_c('div',{staticClass:"detail_subtitle-date"},[_vm._v(" "+_vm._s(_vm.simpleToLocal(_vm.board["board"].created_time))+" ")])]):_c('div',{staticClass:"detail_subtitle"},[_c('span',[_vm._v(" 작성자 : "+_vm._s(_vm.board["board"].real_name)+" / "+_vm._s(_vm.board["board"].created_by.username)+" ")]),_c('span',[_vm._v(" 작성일 : "+_vm._s(_vm.toLocal(_vm.board["board"].created_time))+" ")])])])]),_c('hr'),_c('el-main',[_c('div',{staticClass:"detail_content"},[_c('div',{staticClass:"detail_optionbtn"},[_c('el-button',{attrs:{"size":_vm.$store.state.isMobile ? 'mini' : 'medium'},on:{"click":_vm.list}},[_vm._v("목록")]),(
              _vm.isSuperAdmin ||
              (_vm.user.profile.user && _vm.user.profile.user.id) ===
                (_vm.board['board']['created_by'] && _vm.board['board'].created_by.id)
            )?_c('el-button',{attrs:{"size":_vm.$store.state.isMobile ? 'mini' : 'medium'},on:{"click":_vm.updateData}},[_vm._v("수정")]):_vm._e(),(
              _vm.isSuperAdmin ||
              (_vm.user.profile.user && _vm.user.profile.user.id) ===
                (_vm.board['board']['created_by'] && _vm.board['board'].created_by.id)
            )?_c('el-button',{attrs:{"size":_vm.$store.state.isMobile ? 'mini' : 'medium'},on:{"click":function($event){return _vm.secretBoard(_vm.board['board'].flag)}}},[(_vm.board['board'].flag == 3)?_c('span',[_vm._v(" 공개 ")]):_c('span',[_vm._v(" 비공개 ")])]):_vm._e(),(_vm.isSuperAdmin)?_c('el-button',{attrs:{"size":_vm.$store.state.isMobile ? 'mini' : 'medium'},on:{"click":function($event){return _vm.changeAnnounce(_vm.board['board']['isNotice'])}}},[(_vm.board['board'].isNotice)?_c('span',[_vm._v(" 공지해제 ")]):_c('span',[_vm._v(" 공지하기")])]):_vm._e(),(
              _vm.isSuperAdmin ||
              (_vm.user.profile.user && _vm.user.profile.user.id) ===
                (_vm.board['board']['created_by'] && _vm.board['board'].created_by.id)
            )?_c('el-button',{attrs:{"size":_vm.$store.state.isMobile ? 'mini' : 'medium'},on:{"click":_vm.deleteBoard}},[_vm._v("삭제")]):_vm._e(),(_vm.isSuperAdmin)?_c('el-dropdown',{staticStyle:{"margin-left":"15px"},attrs:{"size":_vm.$store.state.isMobile ? 'mini' : 'medium',"type":_vm.type},on:{"command":_vm.handleSetType}},[_c('el-button',{attrs:{"type":_vm.type,"size":_vm.$store.state.isMobile ? 'mini' : 'medium'}},[_vm._v(" "+_vm._s(_vm.typeText)),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"0"}},[_vm._v("new")]),_c('el-dropdown-item',{attrs:{"command":"1"}},[_vm._v("처리중")]),_c('el-dropdown-item',{attrs:{"command":"2"}},[_vm._v("완료")])],1)],1):_vm._e()],1),_c('BoardHighlight',{staticClass:"bg-code ck-content",attrs:{"code":_vm.content}})],1)]),_c('el-footer',[_vm._l((_vm.board['board'].comments),function(c){return _c('div',{key:c.id,staticClass:"detail_comment"},[_c('div',{staticClass:"board_comment-wrapper"},[_c('div',{staticClass:"board_comment"},[(_vm.$store.state.isMobile)?_c('div',[_c('div',{staticClass:"detail_comment_font"},[_vm._v(" "+_vm._s(c.real_name)+" "),_c('span',[_vm._v(" "+_vm._s(_vm.commentToLocal(c.created_time))+" ")])])]):_c('span',[_c('span',{staticClass:"detail_comment_font"},[_vm._v(_vm._s(c.real_name)+" ")]),_c('span',{staticClass:"detail_comment_font"},[_vm._v(" "+_vm._s(_vm.commentToLocal(c.created_time)))])]),_c('div',{staticClass:"button-wrapper",staticStyle:{"margin-left":"auto"}},[(
                  _vm.isSuperAdmin ||
                  (_vm.commentIndex !== c.id &&
                    _vm.user.profile.user &&
                    _vm.user.profile.user.id === c.created_by.id)
                )?_c('el-button',{staticClass:"detail_comment_button",attrs:{"size":_vm.$store.state.isMobile ? 'mini' : 'medium',"disabled":_vm.flag},on:{"click":function($event){return _vm.chageflag(c.id, c.comment)}}},[_vm._v("수정")]):_vm._e(),(
                  _vm.isSuperAdmin ||
                  (_vm.commentIndex !== c.id &&
                    _vm.user.profile.user &&
                    _vm.user.profile.user.id === c.created_by.id)
                )?_c('el-button',{staticClass:"detail_comment_button",attrs:{"size":_vm.$store.state.isMobile ? 'mini' : 'medium'},on:{"click":function($event){return _vm.secretComment(c.id, c.flag)}}},[(c.flag == 3)?_c('span',[_vm._v(" 공개 ")]):_c('span',[_vm._v(" 비공개 ")])]):_vm._e(),(
                  _vm.isSuperAdmin ||
                  (_vm.user.profile.user &&
                    _vm.user.profile.user.id === c.created_by.id)
                )?_c('el-button',{staticClass:"detail_comment_button",attrs:{"size":_vm.$store.state.isMobile ? 'mini' : 'medium'},on:{"click":function($event){return _vm.deleteComment(c.id)}}},[_vm._v("삭제")]):_vm._e()],1)]),(_vm.flag && _vm.commentIndex === c.id)?_c('div',{staticClass:"textarea"},[_c('VueCkEditor',{attrs:{"data":_vm.comment,"toolbar":"comment"},on:{"setContent":_vm.setComment}}),_c('el-button',{staticClass:"detail_comment_button",on:{"click":function($event){return _vm.changeComment(c.id)}}},[_vm._v("변경")]),_c('el-button',{staticClass:"detail_comment_button",on:{"click":function($event){return _vm.cancle()}}},[_vm._v("취소")])],1):_vm._e(),(
              c.flag === 3 &&
              (_vm.isSuperAdmin || c.created_by.id === _vm.user.profile.user.id) &&
              !_vm.flag
            )?_c('div',{staticClass:"comment-font comment-style"},[_c('BoardHighlight',{staticClass:"bg-code ck-content",attrs:{"code":c.comment}})],1):(c.flag === 3 && !_vm.flag)?_c('div',{staticClass:"comment-font"},[_vm._v(" 비공개 댓글입니다. 🔒 ")]):(!_vm.flag)?_c('div',{staticClass:"comment-font"},[_c('BoardHighlight',{staticClass:"bg-code ck-content",attrs:{"code":c.comment}})],1):_vm._e()])])}),_c('Comment')],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }