import Vue from "vue";
import router from "../oj/router/routes";
import axios from "axios";
import utils from "@/utils/utils";
Vue.prototype.$http = axios;
axios.defaults.baseURL = "/api";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

export default {
  // 로그인
  login(username, password) {
    return ajax("login", "post", {
      data: {
        username,
        password
      }
    });
  },
  logout() {
    return ajax("logout", "get");
  },
  getProfile() {
    return ajax("profile", "get");
  },
  // 뉴스 목록 받기
  getAnnouncementList(offset, limit) {
    return ajax("admin/announcement", "get", {
      params: {
        paging: true,
        offset,
        limit
      }
    });
  },
  // 뉴스 삭제
  deleteAnnouncement(id) {
    return ajax("admin/announcement", "delete", {
      params: {
        id
      }
    });
  },
  // 뉴스 수정
  updateAnnouncement(data) {
    return ajax("admin/announcement", "put", {
      data
    });
  },
  // 뉴스 추가
  createAnnouncement(data) {
    return ajax("admin/announcement", "post", {
      data
    });
  },
  // 사용자 목록 가져 오기
  getUserList(offset, limit, keyword) {
    let params = { paging: true, offset, limit };
    if (keyword) {
      params.keyword = keyword;
    }
    return ajax("admin/user", "get", {
      params: params
    });
  },
  // 개별 사용자 정보 얻기
  getUser(id) {
    return ajax("admin/user", "get", {
      params: {
        id
      }
    });
  },
  // 사용자 수정
  editUser(data) {
    return ajax("admin/user", "put", {
      data
    });
  },
  deleteUsers(id) {
    return ajax("admin/user", "delete", {
      params: {
        id
      }
    });
  },
  //230216 추가
  hideUsers(id) {
    return ajax("admin/user", "patch", {
      params: {
        id
      },
      data:{
        flag : 4,
      }
    });
  },

  recoveredUsers(id) {
    return ajax("admin/user", "patch", {
      params: {
        id
      },
      data:{
        flag : 0,
      }
    });
  },

  importUsers(users) {
    return ajax("admin/user", "post", {
      data: {
        users
      }
    });
  },
  generateUser(data) {
    return ajax("admin/generate_user", "post", {
      data
    });
  },
  getLanguages() {
    return ajax("languages", "get");
  },
  getSMTPConfig() {
    return ajax("admin/smtp", "get");
  },
  createSMTPConfig(data) {
    return ajax("admin/smtp", "post", {
      data
    });
  },
  editSMTPConfig(data) {
    return ajax("admin/smtp", "put", {
      data
    });
  },
  testSMTPConfig(email) {
    return ajax("admin/smtp_test", "post", {
      data: {
        email
      }
    });
  },
  getWebsiteConfig() {
    return ajax("admin/website", "get");
  },
  editWebsiteConfig(data) {
    return ajax("admin/website", "post", {
      data
    });
  },
  getJudgeServer() {
    return ajax("admin/judge_server", "get");
  },
  getJudgeServerLast() {
    return ajax("admin/judge_server-last", "get");
  },
  deleteJudgeServer(hostname) {
    return ajax("admin/judge_server", "delete", {
      params: {
        hostname: hostname
      }
    });
  },
  //닉네임 변경 추가
  changeJudgeServernickName(data){
    return ajax('admin/judge_server',"patch" , {
      data
    })
  },

  updateJudgeServer(data) {
    return ajax("admin/judge_server", "put", {
      data
    });
  },
  getInvalidTestCaseList() {
    return ajax("admin/prune_test_case", "get");
  },
  pruneTestCase(id) {
    return ajax("admin/prune_test_case", "delete", {
      params: {
        id
      }
    });
  },
  createContest(data) {
    return ajax("admin/contest", "post", {
      data
    });
  },
  getContest(id) {
    return ajax("admin/contest", "get", {
      params: {
        id
      }
    });
  },
  editContest(data) {
    return ajax("admin/contest", "put", {
      data
    });
  },
  changeTeacherCodeVisible(contestId, visible) {
    return ajax("admin/contest/teacher_code_visible", "post", {
      data: {
        id: contestId,
        teacher_code_visible: visible
      }
    });
  },
  getContestList(offset, limit, keyword) {
    let params = { paging: true, offset, limit };
    if (keyword) {
      params.keyword = keyword;
    }
    return ajax("admin/contest", "get", {
      params: params
    });
  },
  getContestAnnouncementList(contestID) {
    return ajax("admin/contest/announcement", "get", {
      params: {
        contest_id: contestID
      }
    });
  },
  createContestAnnouncement(data) {
    return ajax("admin/contest/announcement", "post", {
      data
    });
  },
  deleteContestAnnouncement(id) {
    return ajax("admin/contest/announcement", "delete", {
      params: {
        id
      }
    });
  },
  updateContestAnnouncement(data) {
    return ajax("admin/contest/announcement", "put", {
      data
    });
  },
  getProblemTagList() {
    return ajax("problem/tags", "get");
  },
  compileSPJ(data) {
    return ajax("admin/compile_spj", "post", {
      data
    });
  },
  createProblem(data) {
    return ajax("admin/problem", "post", {
      data
    });
  },
  editProblem(data) {
    return ajax("admin/problem", "put", {
      data
    });
  },
  deleteProblem(id) {
    return ajax("admin/problem", "delete", {
      params: {
        id
      }
    });
  },
  getProblem(id) {
    return ajax("admin/problem", "get", {
      params: {
        id
      }
    });
  },
  getProblemList(params) {
    params = utils.filterEmptyValue(params);
    return ajax("admin/problem", "get", {
      params
    });
  },
  getContestProblemList(params) {
    params = utils.filterEmptyValue(params);
    return ajax("admin/contest/problem", "get", {
      params
    });
  },
  getContestProblem(id) {
    return ajax("admin/contest/problem", "get", {
      params: {
        id
      }
    });
  },
  createContestProblem(data) {
    return ajax("admin/contest/problem", "post", {
      data
    });
  },
  editContestProblem(data) {
    return ajax("admin/contest/problem", "put", {
      data
    });
  },
  deleteContestProblem(id) {
    return ajax("admin/contest/problem", "delete", {
      params: {
        id
      }
    });
  },
  makeContestProblemPublic(data) {
    return ajax("admin/contest_problem/make_public", "post", {
      data
    });
  },
  addProblemFromPublic(data) {
    return ajax("admin/contest/add_problem_from_public", "post", {
      data
    });
  },
  addProblemFromContestProblem(data) {
    return ajax("admin/contest/add_problem_from_contest_problem", "post", {
      data
    });
  },
  getReleaseNotes() {
    return ajax("admin/versions", "get");
  },
  getDashboardInfo() {
    return ajax("admin/dashboard_info", "get");
  },
  getSessions() {
    return ajax("sessions", "get");
  },
  exportProblems(data) {
    return ajax("export_problem", "post", {
      data
    });
  },
  // level-problem
  getProblemStepList() {
    // params = utils.filterEmptyValue(params)
    return ajax("admin/problem_step", "get", {});
  },
  getProblemStep(problem_step_id) {
    return ajax("admin/problem_step", "get", {
      params: {
        problem_step_id
      }
    });
  },
  createProblemStep(data) {
    return ajax("admin/problem_step", "post", {
      data
    });
  },
  editProblemStep(data) {
    return ajax("admin/problem_step", "patch", {
      data
    });
  },
  deleteProblemStep(problem_step_id) {
    return ajax("admin/problem_step", "delete", {
      params: {
        problem_step_id
      }
    });
  },
  // problem_step기반
  getProblemStepLevelList(problem_step_id) {
    return ajax("admin/problem_step_level", "get", {
      params: {
        problem_step_id
      }
    });
  },
  getStepLevel(problem_step_level_id) {
    return ajax("admin/problem_step_level", "get", {
      params: {
        problem_step_level_id
      }
    });
  },
  createProblemStepLevel(data) {
    // console.log(data);
    return ajax("admin/problem_step_level", "post", {
      data
    });
  },
  deleteProblemStepLevel(problem_step_level_id) {
    return ajax("admin/problem_step_level", "delete", {
      params: {
        problem_step_level_id
      }
    });
  },
  editProblemStepLevel(data) {
    return ajax("admin/problem_step_level", "patch", {
      data
    });
  },
  // problemStepLevelDetailList
  getProblemStepLevelDetailList(problem_step_level_id) {
    return ajax("admin/problem_step_level_detail", "get", {
      params: {
        problem_step_level_id
      }
    });
  },
  getProblemStepLevelDetail(problem_step_level_detail_id) {
    return ajax("admin/problem_step_level_detail", "get", {
      params: {
        problem_step_level_detail_id
      }
    });
  },
  createProblemStepLevelDetail(data) {
    return ajax("admin/problem_step_level_detail", "post", {
      data
    });
  },
  editProblemStepLevelDetail(data) {
    return ajax("admin/problem_step_level_detail", "patch", {
      data
    });
  },
  deleteProblemStepLevelDetail(problem_step_level_detail_id) {
    return ajax("admin/problem_step_level_detail", "delete", {
      params: {
        problem_step_level_detail_id
      }
    });
  },
  changeOrder(type, order) {
    return axios.post("admin/problem_change_order", {
      [Object.keys(type)[0]]: type[Object.keys(type)[0]],
      order: order + 1
    });
    // return ajax('problem_change_order', 'post', {
    //   data:{
    //     [Object.keys(type)[0]]:type[Object.keys(type)[0]],
    //     order:order+1
    //   }
    // })
  },

  //태그 관련 API

  getTagList(params){
    return ajax('admin/problem/tags', 'get', {
      params
    })
  },
  createTag(data){
    return ajax('admin/problem/tags', 'post', {
      data
    })
  },
  updateTag(data){
    return ajax('admin/problem/tags', 'patch', {
      data
    })
  },
  deleteTag(params){
    return ajax('admin/problem/tags', 'delete', {
      params
    })
  },
  

  // createMincodingAuth(name) {
  //   return ajax("mincoding_auth", "post", {
  //     data: { name }
  //   });
  // },
  // getMincodingAuthList() {
  //   return ajax("mincoding_auth", "get");
  // },
  // getMincodingAuth(id) {
  //   return ajax("mincoding_auth", "get", {
  //     params: {
  //       auth_id: id
  //     }
  //   });
  // },
  // updateMincodingAuth(auth_id, name) {
  //   return ajax("mincoding_auth", "patch", {
  //     data: {
  //       auth_id,
  //       name
  //     }
  //   });
  // },
  deleteMincodingAuth(auth_id) {
    return ajax("mincoding_auth", "delete", {
      params: { auth_id }
    });
  },
  getSecretCodeAuth(data) {
    return ajax("secret_code", "get", { params: data });
  },
  createSecretCodeAuth(data) {
    return ajax("secret_code_auth", "post", { data });
  },
  updateSecretCodeAuth(data) {
    return ajax('secret_code_auth', 'patch', {
      data
    })
  },
  deleteSecretCodeAuth(auth_id) {
    return ajax('secret_code', 'delete', {
      params:{
        auth_id
      }
    })
  },
  updateUserAuth(data){
    return ajaxNotMessage('user_auth', 'patch', {
      data
    })
  },
  getUserInfo (username = undefined) {
    return ajax('profile', 'get', {
      params: {
        username
      }
    })
  },
  getProblemStepAdmin(problem_step_id) {
    return ajax('admin/problem_step_admin', 'get', {
      params: {
        problem_step_id
      }
    })
  },

  // 이미지파일 업로드 
  uploadImageFile(formData){
    return axios.post('upload_image',formData,{
      
    }
    )
  },

  copyContest(data){
    return axios.post('admin/contest/copy', data)
  },

  getNoticeList (params) {
    // params.limit = limit
    // params.offset = offset
    return ajax('notice-list', 'get', {
      params
    })
  },







};

/**
 * @param url
 * @param method get|post|put|delete...
 * @param params like queryString. if a url is index?a=1&b=2, params = {a: '1', b: '2'}
 * @param data post data, use for method put|post
 * @returns {Promise}
 */
function ajax(url, method, options) {
  if (options !== undefined) {
    var { params = {}, data = {} } = options;
  } else {
    params = data = {};
  }
  return new Promise((resolve, reject) => {
    axios({
      url,
      method,
      params,
      data
    }).then(
      res => {
        // API는 오류 유무에 따라 오류가 있는지 여부를 정상적으로 반환합니다 (상태 = 20x).
        if (res.data.error !== null) {
          Vue.prototype.$error(res.data.data);
          reject(res);
          // 백엔드가 로그인으로 돌아 가면 세션이 유효하지 않으며 현재 로그인 한 사용자는 로그 아웃해야합니다.
          if (res.data.data.startsWith("Please login")) {
            router.push({ name: "login" });
          }
        } else {
          resolve(res);
          if (method !== "get") {
            Vue.prototype.$success("Succeeded");
          }
        }
      },
      res => {
        // API 요청이 비정상이며 일반적으로 서버 오류 또는 네트워크 오류입니다.
        reject(res);
        Vue.prototype.$error(res.data.data);
      }
    );
  });
}

/**
 * @param url
 * @param method get|post|put|delete...
 * @param params like queryString. if a url is index?a=1&b=2, params = {a: '1', b: '2'}
 * @param data post data, use for method put|post
 * @returns {Promise}
 */
 function ajaxNotMessage(url, method, options) {
  if (options !== undefined) {
    var { params = {}, data = {} } = options;
  } else {
    params = data = {};
  }
  return new Promise((resolve, reject) => {
    axios({
      url,
      method,
      params,
      data
    }).then(
      res => {
        // API는 오류 유무에 따라 오류가 있는지 여부를 정상적으로 반환합니다 (상태 = 20x).
        if (res.data.error !== null) {
          Vue.prototype.$error(res.data.data);
          reject(res);
          // 백엔드가 로그인으로 돌아 가면 세션이 유효하지 않으며 현재 로그인 한 사용자는 로그 아웃해야합니다.
          if (res.data.data.startsWith("Please login")) {
            router.push({ name: "login" });
          }
        } else {
          resolve(res);
          if (method !== "get") {
            // Vue.prototype.$success("Succeeded");
          }
        }
      },
      res => {
        // API 요청이 비정상이며 일반적으로 서버 오류 또는 네트워크 오류입니다.
        reject(res);
        Vue.prototype.$error(res.data.data);
      }
    );
  });
}