import { render, staticRenderFns } from "./MiracomTestReviewDetail.vue?vue&type=template&id=923b8416&scoped=true"
import script from "./MiracomTestReviewDetail.vue?vue&type=script&lang=js"
export * from "./MiracomTestReviewDetail.vue?vue&type=script&lang=js"
import style0 from "./MiracomTestReviewDetail.vue?vue&type=style&index=0&id=923b8416&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "923b8416",
  null
  
)

export default component.exports