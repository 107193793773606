<template>
  <Panel shadow :padding="10">
    <div slot="title">
      {{ title }}
    </div>
    <div slot="extra">
      
      <Button
        v-if="isSuperAdmin && $route.path==='/'"
        type="info"
        @click="onOpenEditDialog"
        >공지사항 수정</Button
      >
      <Button
      style="margin-top: 47px;"
        v-else-if="isSuperAdmin"
        type="info"
        @click="onOpenEditDialog"
        >강사님 코드 수정</Button
      >
    </div>
    <div>
      <BoardHighlight
        v-katex
        :code="content"
        key="content"
        class="content-container markdown-body"
      ></BoardHighlight>
    </div>

    <!-- 강사님 코드 수정 다이얼로그 -->
    <el-dialog
      top="20px"
      :width="$store.state.isMobile ? '100vw' : '80vw'"
      :title=" $route.path==='/'? '공지사항 수정' : '강사님 코드 수정'"
      :visible.sync="showEditAnnouncementDialog"
      @open="onOpenEditDialog"
      :close-on-click-modal="false"
    >
      <el-form label-position="top">
        <el-form-item :label="$t('m.Announcement_Title')" for="$t('m.Announcement_Title')" required>
          <el-input
            id="$t('m.Announcement_Title')"
            v-model="tutor.title"
            :placeholder="$t('m.Announcement_Title')"
            class="title-input"
          >
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('m.Announcement_Content')"
        required>
        <VueCkEditor :data="tutor.content" @setContent="setContent"></VueCkEditor>
          <!-- <Simditor v-model="tutor.content"></Simditor> -->
        </el-form-item>
        <div class="visible-box">
          <el-switch
            v-model="tutor.visible"
            active-text="공개"
            inactive-text="비공개"
          >
          </el-switch>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="showEditAnnouncementDialog = false">Cancel</el-button>
        <el-button type="primary" @click.native="submitTutorCode">저장</el-button>
      </span>
    </el-dialog>
  </Panel>
</template>

<script>
import Simditor from "@/pages/admin/components/Simditor";
import api from "@oj/api";
import adminApi from "@admin/api";
import Pagination from "@oj/components/Pagination";
import { mapGetters, mapActions, mapState } from "vuex";
import BoardHighlight from "@/pages/oj/components/BoardHighlight";
import VueCkEditor from "@/pages/admin/components/VueCkEditor.vue";

export default {
  name: "AnnouncementDetail",
  components: {
    Pagination,
    Simditor,
    BoardHighlight,
    VueCkEditor
  },
  data() {
    return {
      showEditAnnouncementDialog: false,
      limit: 10,
      total: 10,
      btnLoading: false,
      tutor: {
        title: "",
        visible: true,
        content: ""
      },
      announcement: "",
    };
  },
  mounted() {
    this.getContestAnnouncement();
    this.getProfile();
  },
  methods: {
    ...mapActions(["getProfile", "changeModalStatus"]),
        setContent(e) {
      this.tutor.content = e;
    },
    getContestAnnouncement(){
      if(this.isContest){
        api.getContestAnnouncement(this.$route.params.contestID, this.$route.params.announcementID)
          .then((res) => {
            this.announcement = res.data.data[0];
            this.tutor.title = this.announcement.title;
            this.tutor.content = this.announcement.content;
            this.tutor.visible = this.announcement.visible;
          })
          .catch((error) => {
            console.log('error in getContestAnnouncement -> ', error);
          })
      } else {
        api.getAnnouncementList().then(res =>{

        this.announcement = res.data.data.results.filter(e => e.id === this.$route.params.announcementID)[0]
        this.tutor.title = this.announcement.title;
        this.tutor.content = this.announcement.content;
        this.tutor.visible = this.announcement.visible;
      }
      )
      }
    },
    async submitTutorCode() {
      // superAdmin일때만
      // http://localhost:8081/api/admin/contest/announcement
      // 데이터가 없으면 post
      // 데이터가 있으면 put

      this.showEditAnnouncementDialog = true;
      // 제출 -> 갱신
      const data = {
        id: this.announcement.id,
        title: this.tutor.title,
        content: this.tutor.content,
        visible: this.tutor.visible,
      };

      if(this.isContest){
      adminApi.updateContestAnnouncement(data)
        .then((res) => {
          this.showEditAnnouncementDialog = false;
          // 변경사항 적용
          this.getContestAnnouncement();
          
        });
      }else{
        adminApi.updateAnnouncement(data)
        .then((res) => {
          this.showEditAnnouncementDialog = false;
          // 변경사항 적용
          this.getContestAnnouncement();
          this.$router.push("/")
        });
      }
    },
    onOpenEditDialog() {
      this.showEditAnnouncementDialog = true;
    }
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["user", "isAuthenticated", "isAdminRole", "isSuperAdmin"]),
    title() {
      return this.announcement ? this.announcement.title : "로딩 중";
    },
    content() {
      return this.announcement ? this.announcement.content : "로딩 중";
    },
    visible() {
      return this.tutor ? this.tutor.visible : "로딩 중";
    },
    isLoaded() {
      return this.announcement ? true : false;
    },
    isContest() {
      return !!this.$route.params.contestID;
    },
  }
};
</script>

<style scoped lang="less">
.content-container {
  padding: 0 20px 20px 20px;
}

changeLocale .announcement-animate-enter-active {
  animation: fadeIn 1s;
}
</style>
